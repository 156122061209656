export const SUBSCRIPTION_NOTIFICATION_EVENTS_FRAGMENT = `
fragment subscriptionNotificationEventsFragment on NotificationOutput {
  type
  success
  requestId
  data {
    uid
    action
    createdTime
    createdTimestamp
    triggered {
      __typename
      ... on Survey {
        uid
        code
        title
        instructions
        strategy
        layout
        loginRequired
        editable
        maxAnswers
        startTime
        endTime
      }
      ... on CompanyUserRole {
        uid
        state
        moderator
        company {
          uid
          name
        }
        role {
          uid
          code
        }
        user {
          uid
        }
      }
      ... on CommunityUserConnection {
        uid
        linkState
        user {
          uid
          firstName
          lastName
          jobTitle
        }
        connectedUser {
          uid
          firstName
          lastName
          jobTitle
        }
      }
      ... on MeetingParticipant {
        uid
        state
        meeting {
          uid
          subject
          isCancelled
        }
      }
      ... on Meeting {
        uid
        subject
        isCancelled
        participants {
          uid
          state
          user {
            uid
          }
        }
      }
      ...on Comment {
            uid
            textContent
            parentComment {
              uid
            }
          }
      ... on Session {
        uid
        name
      }
      ... on MeetingRequest {
        uid
        schemaCode
        message
        readState
        trash
        timezoneName
        startTime
        endTime
        createdTime
        startTimestamp
        endTimestamp
        createdTimestamp
        exhibitor {
          uid
          name
          logoFileResource {
            schemaCode
            path
          }
        }
        user {
          uid
          firstName
          lastName
          jobTitle
          employerName
          pictureFileResource {
            path
            schemaCode
          }
        }
        companyRep {
          uid
          firstName
          lastName
          jobTitle
          employerName
          pictureFileResource {
            path
            schemaCode
          }
        }
        guests {
          uid
          firstName
          lastName
          jobTitle
          employerName
          pictureFileResource {
            path
            schemaCode
          }
        }
        meetingRequestMeeting {
          uid
          subject
          timeZoneName
          startTime
          startTimestamp
          endTimestamp
          creatorUser {
            uid
            firstName
            lastName
            pictureFileResource {
              path
              schemaCode
            }
          }
          participants {
            uid
            state
            user {
              uid
              firstName
              lastName
              pictureFileResource {
                path
                schemaCode
              }
            }
          }
        }
      }
      ... on CustomNotification {
        uid
        scheduleTime
        scheduleTimestamp
        message
        actionUrl
        actionText
      }
    }
    notifiedUsers {
      uid
      __typename
    }
    initiator {
      __typename
      ... on Exhibitor {
        uid
        name
        logoFileResource {
          schemaCode
          path
        }
      }
      ... on CommunityUser {
        uid
        firstName
        lastName
        pictureFileResource {
          path
          schemaCode
        }
      }
      ... on Session {
        uid
        name
      }
      ... on Meeting {
        uid
        subject
      }
      ... on CustomNotification {
        uid
        scheduleTime
        scheduleTimestamp
        message
        actionUrl
        actionText
      }
    }
  }
}
`;
